<template>
  <ftx-dialog
    :dialog="dialog"
    position="standard"
    :maximized="isMobile ? true : false"
    :persistent="true"
    className="announcement-modal"
  >
    <template v-slot:DialogContent v-if="announcements.length">
      <q-card class="announcement-card">
        <q-card-section class="card-header items-center">
          <div class="heading">
            <q-chip color="primary">
              <q-icon color="white" name="campaign" />
            </q-chip>
            <div class="text-h4">Announcements</div>
          </div>
          <q-btn
            round
            flat
            icon="close"
            color="black"
            size="16px"
            class="no-hover"
            v-close-popup
          />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div
            class="scroll q-pt-none bulletListScroll"
            :style="
              isMobile
                ? 'height: calc(100dvh - 180px)'
                : 'max-height: calc(100dvh - 250px)'
            "
          >
            <ul class="bulletList" v-if="announcements.length">
              <li
                v-for="(announcement, index) in announcements"
                :key="`${announcement.id}-${index}`"
              >
                <div>
                  <div class="text-subtitle2 text-weight-bold">{{
                    announcement.title
                  }}</div>
                  <div
                    class="text-subtitle2"
                    v-html="announcement.description"
                  />
                </div>
              </li>
            </ul>
            <!-- <div
                class="q-my-sm text-dark q-px-sm"
                v-for="(announcement, index) in announcements"
                :key="index"
              >
                <div class="text-subtitle2 text-weight-bold">{{
                  announcement.title
                }}</div>
                <div class="text-subtitle2" v-html="announcement.description" />
              </div> -->

            <!-- <EmptyComponent v-else image="images/empty-announcement.png">
              <p>There is no any announcement.</p>
            </EmptyComponent> -->
          </div>
        </q-card-section>

        <q-card-actions align="center" class="q-pb-lg">
          <q-btn
            unelevated
            no-caps
            label="Mark Read"
            color="primary"
            :size="isMobile ? '14px' : '16px'"
            :padding="isMobile ? '4px 16px' : '6px 24px'"
            @click="markRead"
          />
        </q-card-actions>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AnnouncementDialog',
  data: function () {
    return {
      dialog: 'AnnouncementDialog',
    }
  },
  computed: {
    ...mapGetters('persistedLocal', ['announcementUpdate']),
    dialogState: {
      get() {
        console.log(this.getDialogState(this.dialog))
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    announcements() {
      let announcements = this.commonStateByKey('announcements') || []

      if (this.properties.showUnread) {
        if (!Object.keys(this.announcementUpdate).length) return announcements
        else
          return announcements.filter((announcement) => {
            return (
              !this.announcementUpdate?.hasOwnProperty(announcement.id) ||
              (this.announcementUpdate?.hasOwnProperty(announcement.id) &&
                this.announcementUpdate[announcement.id] !=
                  announcement.updatedAt)
            )
          })
      }

      return announcements
    },
  },
  methods: {
    markRead() {
      this.addAnnouncement()
      this.dialogState = false
    },
    addAnnouncement() {
      var obj = {}
      for (var i = 0; i < this.announcements.length; i++) {
        obj[this.announcements[i].id] = this.announcements[i].updatedAt
      }

      this.$store.commit('persistedLocal/SET_ANNOUNCEMENT_UPDATE', obj)
    },
  },
}
</script>

<style lang="scss" scoped></style>
